.currency-news {
    border:1px solid #f0f0f0;
    border-radius: 10px;
    margin-bottom: 20px;
    padding:20px;
    height:590px;
    display: flex;
    flex-direction: column;

    .news-container {
        position:relative;
        display:flex;
        flex-direction: column;
        overflow-y: auto;
        padding-right: 10px;
        /* width */
        &::-webkit-scrollbar {
            width: 10px;
            position: absolute;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1; 
            border-radius: 20px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888; 
            border-radius: 20px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555; 
        }  
        
        .feed-item {
            display: block;
            background: #fbfbfb;
            margin-bottom: 10px;
            text-align: left;
            border: 1px solid #ebebeb;
            border-radius: 5px;
            a {
                display:block;
                padding: 20px;
                color: #000;
                display:flex;
                .icon {
                    width: 80px;
                    height: 80px;
                    background: #FFF;
                    border-radius: 40px;
                    border: 1px solid #f2f2f2;
                    margin-right: 15px;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .content {
                    span{
                        font-size: 12px;
                        color: #959595;
                    }
                    h3 {
                        margin: 10px 0;
                    }
                }
            }
        }
    }

}