.futures-detail {
    .ant-card {
        border-radius: 10px;
        .ant-card-body {
            display:flex;
        }
    }
    .ant-statistic {
        flex-grow:1;
        .ant-statistic-title {
            color:#000;
            font-weight:500;
        }
        .ant-statistic-content {
            font-weight: 500;
        }
    }

    .position-buttons {
        text-align:left;
        display:flex;
        flex-wrap: wrap;
        margin-bottom:15px;
        .ant-btn {
            width:calc(16.66666% - 10px);
            margin:5px;
            font-weight:500;
            &.active {
                border-color:#e5ac17;
                background-color: #e5ac17;
                color:#FFF;
            }
        }
    }
}