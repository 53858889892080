.user-info {
    margin-left: auto;
    display: flex;
    background-color: #f1f1f1;
    height: 80%;
    margin-top: 7px;
    border-radius: 50px;
    align-items: center;
    color: #000;
    padding: 0 10px;
    justify-content: space-between;
    .ant-avatar {

    }
    .user-name {
        color: #000;
        font-size: 12px;
        flex-grow: 1;
        flex-shrink: 1;
        margin: 0 10px;
        line-height: 20px;
        text-align: left;
        p {
            margin: 0;
            padding: 0;
            font-style: italic;
            font-size:12px;
        }
        h4 {
            margin: 0;
            padding: 0;
            font-size: 14px;
        }
    }
    .ant-btn {
        background-color: #8b8b8b;
        border-color: #8b8b8b;        
    }
}