.analysis {
    width:100%;
    .btn-analysis {
        background-color: #e5ac17;
        border-color: #e5ac17;
        height: 60px;
        padding: 0 30px;
        border-radius: 6px;
        transition:all 0.2s linear;
        margin-bottom:10px;
        &:hover {
            background-color: #f7931a;
            border-color: #f7931a;         
        }
    }
    .ant-tabs-tab-btn {
        font-weight: 500;
        font-size: 16px;
        color:#999;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #000;
    }
    .ant-tabs-tab {
        padding: 10px 50px;
    }

    .other-deal-symbol {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        margin-bottom:15px;
        a {
            color:#000;
            display:flex;
            align-items: center;
            width:100%;
        }
        img {
            height: 18px;
            margin-right: 6px;
        }
        .rsi {
            margin-left: auto;
            font-size: 12px;
            font-weight: bold;
            color: #e5ac17;
        }
        .ema200-up-down {
            margin-left: auto;
        }
    }

    .other-deals {
        margin-top:20px;
    }

    .select-model {
        margin-bottom: 10px;
        p {
            margin-bottom: 5px;
        }
    }
}

@media screen and (max-width: 1601px) {
    .analysis {
      width:100%;
    }
  }