.analysis-loading {
    background: rgba(255, 255, 255, 0.7);
    width: 100%;
    position: fixed;
    left: 0;
    height: 100vh;
    top: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .progress-bar {
        width:300px;
        padding-bottom:20px;
        margin-bottom:20px;
        &.completed {
            border-bottom: 1px solid #eee;
        }
    }
    .progress-info {
        font-weight: 500;
    }

    .spinning {
        width: 300px;
        .spin-info {
            font-weight: 500;
        }
    }

    .ant-progress-bg {
        background-color: #E5AC17;
    }

    .ant-progress-status-success {
        .ant-progress-bg {
            background-color: #52c41a;
        }
    }
}