.info-modal-component {
    .info-box {
        position:fixed;
        top:80px;
        right:20px;
        .info-icon {
            cursor: pointer;
            border:4px solid #93cef9;
            border-radius: 50%;
        }
    }
}