.futures-detail {
    width:1440px;
    .ant-card {
        border-radius: 10px;
        .ant-card-body {
            display:flex;
            position:relative;
            .pnl-ratio {
                position: absolute;
                margin: 0 auto;
                left: 0;
                right: 0;
                background: #fbfafa;
                width: fit-content;
                top: 0;
                padding: 10px 15px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                font-weight: 500;
                font-size: 14px;
                border: 1px solid #f0f0f0;
            }
        }
    }
    .ant-statistic {
        flex-grow:1;
        .ant-statistic-title {
            color:#000;
            font-weight:500;
        }
        .ant-statistic-content {
            font-weight: 500;
        }
    }

    .position-buttons {
        text-align:left;
        display:flex;
        flex-wrap: wrap;
        margin-bottom:15px;
        .ant-btn {
            width:calc(16.66666% - 10px);
            margin:5px;
            font-weight:500;
            &.active {
                border-color:#e5ac17;
                background-color: #e5ac17;
                color:#FFF;
            }
        }
    }
}

@media screen and (max-width: 1441px) {
    .futures-detail {
      width:100%;
    }
  }