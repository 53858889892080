.futures {
    .currency-search {
        width: 50%;
        margin: 0 auto;
        padding: 20px;
        margin-bottom: 20px;
        .ant-input-affix-wrapper {
            border-radius: 6px;
        }
        .ant-input {
            line-height: 40px;
        }
    }
}