.coin-status {
    background: #FFF;
    border:1px solid #edebeb;
    border-radius: 10px;
    padding:13px;
    align-items: flex-start;
    margin-bottom: 20px;
    .ant-col {
        padding:10px 10px;
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        border-right: 1px solid #ededed;
        span {
            font-size:12px;
            font-weight:600;
            color:#adadad;
        }
        p {
            margin:0;
            font-size:22px;
            font-weight:700;
            line-height: 1;
        }
        &:last-child {
            border:none;
        }
    }
    .coin-logo {
        flex-direction: row;
        align-items: center;
        justify-content:flex-start;
        p {
            margin-left:10px;
            font-size:20px;
        }
    }

    .current-price {
        display:flex;
        flex-direction: row;
        align-items: center;
        p {
            margin-right:6px;
        }
    }    
    img {
        width:50px;
        height:50px;
    }
    .green {
        color:#0ecb81 !important;
    }
    .red {
        color:#f6465d !important;
    }

    .percent {
        margin-left:5px;
        font-weight: 700 !important;
    }
}