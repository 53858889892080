.calc-input {
    margin-bottom:10px;
}

.calc-table {
    .ant-table-cell {
        padding: 5px 16px !important;
        text-align: center;
        font-weight: 600;
    }
}

.percent-calculator {
    .calc-table {
        max-height:300px;
        overflow-y: scroll;
    }
}
.compare-calculator {
    .input-holder {
        display:flex;
    }
    .compare-result {
        background:rgb(240, 240, 240);
        padding: 10px 0;
        p {
            font-size:30px;
            font-weight:600;
            text-align:center;
            padding:0;
            margin:0;
        }
    }
}
.cumulative-calculator {
    .input-holder {
        display:flex;
    }
    .cumulative-result {
        background:rgb(240, 240, 240);
        padding: 10px 0;
        p {
            font-size:30px;
            font-weight:600;
            text-align:center;
            padding:0;
            margin:0;
        }
        margin-bottom:20px;

    }
    .cumulative-table {
        max-height:300px;
        overflow-y: scroll;      
    }
}