.coin-list-box {
    width: 100%;
    padding: 20px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fbfbfb;
    color: #000;
    margin-bottom: 16px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #efecec;
    align-items: center;
    transition:all .22s linear;
    img {
        height: 80px;
        margin-bottom:10px;
    }
    p {
        margin:0;
        padding:0;
    }
    .symbol-name {
        font-size:18px;
    }
    .full-name {}
    .risk {
        width: 10px;
        height: 10px;
        background: #333;
        display: block;
        margin-left: 5px;
        border-radius: 50%;
        //border: 1px solid #333;
        position: absolute;
        top: 10px;
        right: 20px;
    }
    .red {
        background-color: #f50;
        border-color:#f56e2a;
    }
    .green {
        background-color: #2dd92a;
        border-color:#6bff68;
    }
    .yellow {
        background-color: #ffcc12;
        border-color:#f7cb58;
    }
    .star {
        background-color: inherit;
        border-color: transparent;
        width: auto;
        height: auto;
        .anticon {
        }
    }
}

a:hover {
    .coin-list-box {
        box-shadow: 0 0 20px rgb(0 0 0 / 20%);
        border-color: #dddddd;
        background: #FFF;
    }
}
