.navigation {
    width:auto;
    display: flex;
    align-items: center;
    ul {
        padding:0;
        margin:0;
        display:flex;
        li {
            list-style: none;
            a {
                display:block;
                padding:0 15px;
                color:#FFF;
                transition: all 0.2s linear;

            }
            &:hover {
                a {
                    background-color: #E5AC17;   
                }
            }
            &.active {
                a {
                    background-color: #E5AC17;
                }
            }
        }
    }
    .calc-icon {
        display: flex;
        padding: 0 20px;
        span {
            cursor:pointer;
        }
    }
    .current-model {
        display: flex;
        align-items: center;
        p {color: #FFF; margin:0; display: inline-block; line-height: 1.5; }
    } 
}

@media screen and (max-width: 1601px) {
    .ant-layout-header {
        line-height: 1.8;
    }
    .navigation {
        ul {
            height: 100%;
            li {
                height: 100%;
                display: flex;
                a {
                    display: flex;
                    padding: 0 7px;
                    font-size: 12px;
                    height: 100%;
                    align-items: center;
                }
            }
        }
        .current-model {
            display: flex;
            align-items: center;
            p {
                font-size:12px;
            }
        }
    }
}
