.all-profit-loss-table {
    .ant-table-tbody {
        tr {
            td {
                font-weight:500;
            }
        }
    }

    .symbol {
        display: flex;
        align-items: center;
        a {
            color:#000;
            display:flex;
            align-items: center;
        }
        img {
            height: 18px;
            margin-right: 6px;
        }
        span {

        }
    }
}