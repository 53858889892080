.ant-menu {
    width:100%;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: #E5AC17;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, 
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #E5AC17;
}