.mode15 {
    ul {
        width: fit-content;
        text-align: left;
        margin: 10px auto;
    }
    .btn-analysis {
        background-color: #e5ac17;
        border-color: #e5ac17;
        height: 60px;
        padding: 0 30px;
        border-radius: 6px;
        transition:all 0.2s linear;
        &:hover {
            background-color: #f7931a;
            border-color: #f7931a;         
        }
    }
    .ant-tabs-tab-btn {
        font-weight: 500;
        font-size: 16px;
        color:#999;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #000;
    }
    .ant-tabs-tab {
        padding: 10px 50px;
    }
}

